<template>
  <div>
    <div class="col-xxl-10 mx-auto px-4 pt-5 mt-5 text-center">
      <h1 class="display-4 fw-bold mx-auto" style="max-width: 690px">Verwifi: Your Passport to Fast, Reliable Airbnb Wifi</h1>
      <div class="col-lg-10 mx-auto">
        <p class="lead mb-4">
          Nomads Unite – Share and Access Verified Airbnb Wifi Speeds Across the Globe!
        </p>
        <div class="mb-5 d-flex flex-wrap gap-2 justify-content-center">
          <router-link
            class="btn btn-success btn-lg px-4"
            to="/listing/list"
          >
            <i class="bi bi-house-heart"></i>
            &nbsp; See Listings Map
          </router-link>
          <router-link
            class="btn btn-outline-success btn-lg px-4"
            to="/test/general/create"
          >
            <i class="bi bi-check-circle"></i>
            &nbsp; Complete a Test
          </router-link>
        </div>
      </div>
      <div class="overflow-hidden" style="max-height: 30vh;">
        <router-link to="/listing/list">
          <div class="container px-5">
            <img src="/img/map_screenshot.png" class="img-fluid border rounded-3 shadow-lg mb-4" alt="Example image" width="700" height="500" loading="lazy">
          </div>
        </router-link>
      </div>
    </div>

    <div class="container mt-5 col-xxl-10 px-4 py-5">
      <h2 class="pb-2 border-bottom">Featured Listings</h2>
      <carousel
        :breakpoints="carouselBreakpointSettings"
        :pauseAutoplayOnHover="true"
        :transition="1000"
        snapAlign="start"
        :autoplay="2000"
        :wrapAround="true"
        style="min-height: 300px"
        class="mt-5"
      >
        <slide v-for="listing in featuredListings" :key="listing.listingId">
          <div class="card" style="width: 18rem; height: 300px">
            <img :src="listing.listingDetails.image" class="card-img-top" alt="Listing Image" style="height: 150px; object-fit: cover;">
            <div class="card-body text-start d-flex flex-column justify-content-between">
              <div>
                <h5 class="card-title mt-2" style="max-height: 50px; overflow: hidden;">{{ formatTitle(listing.listingDetails.title) }}</h5>
                <div class="d-flex flex-wrap mt-2">
                  <p class="card-text m-0" style="white-space:nowrap;">👇 {{listing.downloadAvg.toFixed(1)}} /&nbsp;</p>
                  <p class="card-text m-0" style="white-space:nowrap;">☝️ {{listing.uploadAvg.toFixed(1)}} /&nbsp;</p>
                  <p class="card-text m-0" style="white-space:nowrap;">🍺 {{listing.latency.toFixed(0)}} /&nbsp;</p>
                  <p class="card-text m-0" style="white-space:nowrap;">🧪 {{listing.testCount }}</p>
                </div>
              </div>
              <router-link :to="getFullListingUrl(listing)" class="btn btn-primary w-100 mt-2">More Info</router-link>
            </div>
          </div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>

    <div class="container col-xxl-10 px-4 py-5" id="featured-3">
      <h2 class="pb-2 border-bottom">How it works</h2>
      <div class="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <div class="feature col">
          <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
            <span style="font-size: 4rem">🕵️</span>
          </div>
          <h3 class="fs-2">Find Your Ideal Airbnb & Wifi Match</h3>
          <p>
            As a digital nomad, finding the perfect workspace is crucial. 
            Browse our 90+ Airbnb listings with verified wifi speeds for a seamless remote work experience
          </p>
        </div>
        <div class="feature col">
          <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
            <span style="font-size: 4rem">📶</span>
          </div>
          <h3 class="fs-2">Real and Reliable Speeds, Verified for Accuracy</h3>
          <p>
            Avoid uncertainties while working remotely. 
            Verwifi provides verified wifi speeds for each listing, meeting the needs of digital nomads like you.
          </p>
        </div>
        <div class="feature col">
          <div class="feature-icon d-inline-flex align-items-center justify-content-center fs-2 mb-3">
            <span style="font-size: 4rem">🙌</span>
          </div>
          <h3 class="fs-2">Pay It Forward - Test and Help Fellow Travellers</h3>
          <p>
            Contribute to the community by running a Verwifi speed test during your stay. 
            Help fellow digital nomads by sharing exact wifi speeds. 
            Be a wifi hero and support remote work!
          </p>
        </div>
      </div>
    </div>

    <div class="px-4 py-5 text-center">
      <div class="d-block mx-auto mb-4">
        <span style="font-size: 4rem">😰</span>
      </div>
      <div class="mx-auto">
        <h1 class="display-5 fw-bold">The story of our founder sweating the wifi in Mexico.</h1>
      </div>
      <div class="col-lg-10 mx-auto">
        <p class="lead mb-4">
          You've heard of fever sweats, night sweats and even gym sweat. But if you're a digital nomad you're familiar with another kind of sweat and that's bad wifi sweat. 
          It's exactly what our founder had when he arrived for a month of remote work in Mexico at a great Airbnb. With host reported wifi speeds of 60 down
          he thought he was on easy street until he turned his laptop on and realized his wifi speeds were 10 down and .5 up. Zoom calls froze. Online platforms wouldn't load.
          He was sweating, stressed and anxious while his team back home wasn't happy. He knew if he needed verified wifi speeds others did too. The result is Verwifi. 
          It's goal? No sweat remote work.
        </p>
      </div>
    </div>

    <div class="container col-xxl-10 px-4 py-5">
      <div class="row flex-lg-row-reverse align-items-center g-5 py-5">
        <div class="col-10 col-sm-8 col-lg-6">
          <img src="/img/screenshot_listing.png" class="d-block mx-lg-auto img-fluid rounded-3 shadow-lg" alt="Bootstrap Themes" width="700" height="500" loading="lazy">
        </div>
        <div class="col-lg-6">
          <h1 class="display-5 fw-bold lh-1 mb-3">Only Verwifi delivers five speed metrics on unlimited listings.</h1>
          <p class="lead">
            When it comes to getting accurate wifi speeds we have you covered with:
          </p>
          <ul>
            <li class="lead">Upload speeds</li>
            <li class="lead">Download speeds</li>
            <li class="lead">Latency</li>
            <li class="lead">Number of speed tests reported and dates</li>
            <li class="lead">Additional data such as connectivity drops, signal strength, etc</li>
          </ul>
          <p class="lead">
            Verwifi delivers all five metrics
            and best of all? You can use Verwifi as many times as you like. The only caps we like are the ones you wear on your head (we're Canadian after all).
          </p>
        </div>
      </div>
    </div>

    <div class="bg-dark text-secondary px-4 py-5 text-center">
      <div class="py-5">
        <h1 class="display-5 fw-bold text-white">Try Verwifi. <br> Never book in the dark again.</h1>
        <div class="col-lg-6 mx-auto">
          <p class="fs-5 mb-4">
            Verwifi brings an Airbnbs true wifi speed into the light so you can book your next work location with full confidence. 
            It's reliable and made with passion by fellow digital nomads for digital nomads. Sign up today and let us know what you think and hey, enjoy your trip!
          </p>
          <div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
            <router-link
              class="btn btn-outline-success btn-lg px-4"
              to="/listing/list"
            >
              <i class="bi bi-house-heart"></i>
              &nbsp; See Listings
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  components: {
    Carousel,
    Slide,
    Navigation
  },
  data() {
    return {
      featuredListings: [],
      carouselBreakpointSettings: {
        320: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        768: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      }
    }
  },
  mounted() {
    this.$gtag.pageview(this.$route)

    fetch('https://us-central1-verwifi.cloudfunctions.net/getFeaturedListings', {
      method: 'POST',
      body: JSON.stringify({featuredIds: ['36485904', '35353960', '25453384', '34260870', '25181987', '136986', '13547473', '41981433', '19220959', '7652342']})
    })
      .then((res) => {
        res.json()
          .then(data => {
            this.featuredListings = data
          })
      })
  },

  methods: {
    formatTitle(title) {
      return title.split('·')[0]
    },

    getFullListingUrl(listing) {
      const listingData = {
        id: listing.listingId,
        title: listing.listingDetails.title,
        description: '',
        image: listing.listingDetails.image,
        url: `https://airbnb.com/rooms/${listing.listingId}`,
        location: listing.listingDetails.location,
      }

      return `/listing?listingData=${btoa(encodeURIComponent(JSON.stringify(listingData)))}`
    },
  }
}
</script>