import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ScriptX from 'vue-scriptx'
import VueGtag from "vue-gtag";
import Ads from 'vue-google-adsense'

createApp(App)
  .use(store)
  .use(router)
  .use(ScriptX)
  .use(VueGtag, {
    config: { id: "G-XE0RKWZJB4" },
    router
  })
  .use(Ads.AutoAdsense, { adClient: 'ca-pub-4679074829230261', isNewAdsCode: true })
  .mount('#app')
