<template>
  <nav class="navbar navbar-expand-lg bg-light">
    <div class="container">
      <router-link class="navbar-brand d-flex align-items-center" to="/">
        <div width="30" height="24" class="d-flex align-items-center justify-content-center">
          <span style="font-size: 2rem">✅</span>
        </div>
        <span class="h3 m-0" style="2rem">&nbsp; verwifi</span>
      </router-link>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" aria-current="page" to="/">Home</router-link>
          </li>

          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/get-started">Getting Started</router-link>
          </li> -->
          <li class="nav-item">
            <router-link class="nav-link" to="/listing/list">Listings Map</router-link>
          </li>

          <li class="nav-item">
            <router-link class="nav-link" to="/test/general/create">Complete a Test</router-link>
          </li>

          <li class="nav-item">
            <pwa-install-button></pwa-install-button>
          </li>

          <!-- <li class="nav-item">
            <router-link class="nav-link" to="/blog">Blog</router-link>
          </li> -->
        </ul>

        <router-link v-if="!user && !isTestAuthPage()" to="/signup" class="btn btn-success me-2">Sign Up</router-link>
        <router-link v-if="!user && !isTestAuthPage()" to="/signin" class="btn btn-outline-success">Login</router-link>

        <div v-if="user" class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            My Account
          </a>
          <ul class="dropdown-menu">
            <li><router-link class="dropdown-item" to="/profile">Manage Account</router-link></li>
            <li v-if="isAdmin"><router-link class="dropdown-item" to="/admin">Admin</router-link></li>
            <li><button class="dropdown-item" @click="signout">Sign Out</button></li>
          </ul>
        </div>
      </div>
    </div>
  </nav>

  <router-view />

  <div class="container">
    <footer class="py-3 my-4">
      <ul class="nav justify-content-center border-bottom pb-3 mb-3">
        <li class="nav-item"><router-link to="/" class="nav-link px-2 text-muted">Home</router-link></li>
        <!-- <li class="nav-item"><router-link to="/get-started" class="nav-link px-2 text-muted">Getting Started</router-link></li> -->
        <li class="nav-item"><router-link to="/listing/list" class="nav-link px-2 text-muted">Listings Map</router-link></li>
        <li class="nav-item"><router-link to="/test/general/create" class="nav-link px-2 text-muted">Complete a Test</router-link></li>
        <li class="nav-item"><router-link to="/blog" class="nav-link px-2 text-muted">Blog</router-link></li>
        <li class="nav-item"><router-link to="/privacy" class="nav-link px-2 text-muted">Privacy Policy</router-link></li>
      </ul>
      <p class="text-center text-muted">&copy; 2022 Verwifi</p>
    </footer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { app } from '@/firebase/utils'
import { signOut, getAuth, onAuthStateChanged } from "firebase/auth";
import PwaInstallButton from './components/PwaInstallButton.vue';

export default {
  components: { PwaInstallButton },
  mounted() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setUser(user)
      } else {
        this.setUser(null)
      }
    });
  },

  methods: {
    ...mapActions(['setUser']),

    signout() {
      const auth = getAuth(app)

      signOut(auth).then(() => {
          this.setUser(null)
          this.$router.push('/')
        }).catch((error) => {
          console.error(error)
        });
    },

    isTestAuthPage() {
      const params = (new URL(document.location)).searchParams;
      return params.get('test') ? true : false;
    }
  },

  computed: {
    ...mapGetters(['getUser', 'isAdminUser']),
    user() {
      return this.getUser
    },

    isAdmin() {
      return this.isAdminUser
    }
  }
}
</script>
