
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBpa97QKtstyNMAuSjx13r1nykqyKsnp-E",
  authDomain: "verwifi.firebaseapp.com",
  projectId: "verwifi",
  storageBucket: "verwifi.appspot.com",
  messagingSenderId: "485183747534",
  appId: "1:485183747534:web:5184b69b41de35e00d279f",
  measurementId: "G-XE0RKWZJB4"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)