import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/faq',
    name: 'FAQ',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Faq.vue'),
  },
  {
    path: '/get-started',
    name: 'Get Started',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GetStarted.vue'),
  },
  {
    path: '/signup',
    name: 'Sign Up',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SignupView.vue'),
  },
  {
    path: '/signin',
    name: 'Sign In',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SigninView.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UserProfileView.vue'),
  },
  {
    path: '/listing/list',
    name: 'listListings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ListingsView.vue')
  },
  {
    path: '/listing',
    name: 'Listing Info',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DetailListingView.vue'),
  },
  // {
  //   path: '/listings',
  //   name: 'Listing Library',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Listings.vue'),
  // },
  {
    path: '/test/create',
    name: 'Listing Test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestView.vue'),
  },
  {
    path: '/test/general/create',
    name: 'General Listing Test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/GeneralTestView.vue'),
  },
  {
    path: '/admin',
    name: 'Admin Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminDashboard.vue'),
  },
  {
    path: '/admin/test/create',
    name: 'Admin Listing Test',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AdminTestView.vue'),
  },
  {
    path: '/admin/unconfirmed-tests',
    name: 'Admin unconfirmed tests',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/UnconfirmedTestsView.vue'),
  },
  {
    path: '/success',
    name: 'Success',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestSuccessView.vue'),
  },
  {
    path: '/general-success',
    name: 'Test Submission Success',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestGeneralSuccessView.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Privacy.vue'),
  },
  // Blog links -- 
  {
    path: '/blog',
    name: 'Blog',

    component: () => import(/* webpackChunkName: "about" */ '../views/blog/List.vue'),
  },
  {
    path: '/blog/best-questions-to-ask-before-booking-an-airbnb-as-a-digital-nomad',
    name: 'Blog | Best questions to ask before booking an airbnb as a digital nomad',

    component: () => import(/* webpackChunkName: "about" */ '../views/blog/FindAirbnb.vue'),
  },
  {
    path: '/blog/best-ways-to-ensure-fast-airbnb-wifi-speeds',
    name: 'Blog | Best ways to ensure fast Airbnb wifi speeds',

    component: () => import(/* webpackChunkName: "about" */ '../views/blog/WifiSpeeds.vue'),
  },
  {
    path: '/blog/find-the-perfect-airbnb-home-office',
    name: 'Blog | The best Airbnbs for digital nomads: How to find the perfect home office on your next trip',

    component: () => import(/* webpackChunkName: "about" */ '../views/blog/FindThePerfectAirbnbHomeOffice.vue'),
  },
  {
    path: '/blog/airbnb-co-living-pros-and-cons',
    name: 'Blog | The pros and cons of staying in a co-living Airbnb for digital nomads',

    component: () => import(/* webpackChunkName: "about" */ '../views/blog/AirbnbColivingProsAndCons.vue'),
  },
  {
    path: '/blog/airbnb-why-renting-entire-home-is-ideal',
    name: 'Blog | Why renting an entire home on Airbnb is the ideal solution for digital nomads',

    component: () => import(/* webpackChunkName: "about" */ '../views/blog/AirbnbWhyRentingEntireHomeIsIdeal.vue'),
  },
  {
    path: '/blog/airbnb-remote-locations-for-digital-nomads-looking-to-escape-the-city',
    name: 'Blog | The top Airbnbs in remote locations for digital nomads looking to escape the city',

    component: () => import(/* webpackChunkName: "about" */ '../views/blog/AirbnbRemoteLocationsForDigitalNomadsLookingToEscapeTheCity.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `Verwifi - ${to.name}`
  next()
})

export default router
