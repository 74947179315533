import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
  },
  getters: {
    getUser(state) {
      return state.user
    },

    isAdminUser(state) {
      const adminUidArray = ['dZUKZS7xoRMEtQsR9Sp7UC9mS213', 'y2w45Fq5b4NAzKd9s9QARMKZ0A92', 'EAzf4lNXZ7hEJK0prxalMTmEYSH2']
      if (state.user && adminUidArray.includes(state.user.uid)) {
        return true
      } else {
        return false
      }
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user)
    }
  },
  modules: {
  }
})
