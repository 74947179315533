<template>
  <a href="javascript:void(0)" class="nav-link" v-show="showInstallButton" @click="installApp">Install App</a>
</template>

<script>
  export default {
    data() {
      return {
        showInstallButton: false,
        deferredPrompt: null
      };
    },
    mounted() {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        this.deferredPrompt = event;
        this.showInstallButton = true;
      });
    },
    methods: {
      installApp() {
        if (this.deferredPrompt) {
          this.deferredPrompt.prompt();
          this.deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the install prompt.');
            } else {
              console.log('User dismissed the install prompt.');
            }
            this.deferredPrompt = null;
            this.showInstallButton = false;
          });
        }
      }
    }
  };
</script>